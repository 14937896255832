<template>
    <div id="v-pills-assessments" role="tabpanel" aria-labelledby="v-pills-assessments-tab">
       <div class="col content">
          <h5><strong>Assessments</strong></h5>
          <div class="row" v-if="assessments.length">
            <div class="col-md-12 mb-2" v-for="assessment in assessments" :key="assessment.tracker_id">
              <div class="card">
                <div class="card-body">
                  <p class="job_desgination" style="cursor:pointer" @click="showDetails(assessment.tracker_id)">{{assessment.test_name}}</p>
                  <div class="d-flex justify-content-between" v-if="!assessment.test_taken">
                    <div>
                      <p class="mb-0"><strong>Sent by </strong>{{ assessment.client_name}}</p>
                      <p><strong>Last date: </strong>{{ getDateWithTime(assessment.last_date) }}</p>
                    </div>
                    <div>
                      <a class="btn active" v-if="!checkExpired(assessment.last_date)" :href="assessment.join_link" target="_blank">Proceed</a>
                      <div class="btn expired" v-else>Expired</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between" v-else>
                    <div>
                      <div v-for="(company, i) in assessment.company" :key="i">
                        <p class="mb-0"><strong>Sent by </strong>{{ company }}</p>
                      </div>
                      <p class="mb-0 mt-2"><strong>Score: </strong>{{ assessment.score }} out of {{ assessment.total_marks }}</p>
                      <p><strong>Attempted on:</strong> {{ getDateWithTime(assessment.test_taken_on)}}</p>
                    </div>
                    <div>
                      <span class="btn expired" style="cursor: pointer;">Completed</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
       </div>
        <b-modal id="assessment-modal" hide-footer :title="current_assess.test_name" size="lg">
          <div class="container">
            <div class="col">
              <p><strong>Total duration: </strong>{{ current_assess.total_duration}}</p>
              <p><strong>Total marks: </strong> {{ current_assess.total_marks }}</p>
              <div v-if="!current_assess.test_taken">
                <p><strong>Last date: </strong> {{getDateWithTime(current_assess.last_date)}}</p>
                <p v-if="!checkExpired(current_assess.last_date)"><strong><a :href="current_assess.join_link" target="_blank">Click here to go to test</a></strong></p>
              </div>
            </div>
            <section>
                  <table class="table table-bordered w-auto mt-3 mx-auto">
                      <thead>
                          <tr>
                              <th>Section name</th>
                              <th>No. of questions</th>
                          </tr>
                      </thead>
                        <tbody v-if="current_assess.section_question_details">
                          <tr v-for="(section, index) in JSON.parse(current_assess.section_question_details)" :key="index">
                              <td>{{section.section_name}}</td>
                              <td>{{section.total_questions}}</td>
                          </tr>
                      </tbody>
                  </table>
              </section>
          </div>
        </b-modal>
    </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';

import '../assets/hire.css';
export default {
  name: 'Assessments',
  data(){
    return{
      action: new Actions(),
      assessments: [],
      current_assess: {
        test_name: '',
        section_question_details: null,
        test_date_from: '',
        test_date_to: '',
        join_link: '',
        last_date: '',
        total_duration: '',
        total_marks: '',
        total_no_of_questions: ''
      }

      // length: 0,
      // page:1,
      // offset: 0,
      // limit: 25,
      // total: 0,
    }
  },
  beforeMount(){
    this.loadAssessments();
  },
  methods:{
    loadAssessments() {
      let token = this.$cookies.get("hire-seeker").token;
      this.action.getAssessments(token).then((res) => {
          if(res.success) {
              this.assessments = res.assessments;
              // this.limit = res.meta.limit;
              // this.offset = res.meta.offset;
              // this.total = res.meta.total;
              // this.length = Math.ceil(res.meta.total/res.meta.limit);
          }
      }).catch((err) => {
          console.log(err)
      });
    },
    showDetails(tracker_id){
      let token = this.$cookies.get("hire-seeker").token;
      this.action.getTestDetails(tracker_id, token).then((res) => {
          if(res.success) {
              this.current_assess = res.assessment;
              this.$bvModal.show('assessment-modal');
          }
      }).catch((err) => {
          console.log(err)
      });
    },
    checkExpired(ts){
      let current_time = new Date().getTime()/1000;
      if(current_time > ts) return true;
      else return false;
    },
    getTime(ts) {
        var timestamp = ts * 1000;
        var date = new Date(timestamp);
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    },
    getDateWithTime(ts) {
        var months = ['Jan', 'Feb' , 'Mar' , 'Apr' , 'May' , 'June' , 'July' , 'Aug' , 'Sep' , 'Oct' , 'Nov' , 'Dec' ];

        var timestamp = ts * 1000;
        var date = new Date(timestamp);

        return date.getDate()+
            " "+months[date.getMonth()]+
            " "+date.getFullYear() +
            ", "+ date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
              
      },
    pad(n){
      if(n < 10) return '0'+n; else return n;
    },
    checkDate(ts) {
          let exp_ts = ts + 1800;
          let current_time = new Date().getTime()/1000
          if(current_time < ts) return 'upcoming';
          else if(ts < current_time && current_time< exp_ts) return 'active';
          else if(current_time > exp_ts) return 'expired';
      },
    getHelpText(ts) {
        let exp_ts = ts + 1800;
        let current_time = new Date().getTime()/1000
        if(current_time < ts) return 'Interview not started yet, retry at the scheduled time';
        else if(current_time > exp_ts) return 'Interview link has been expired';
    },
  }
}
</script>
<style>
  .btn.expired {
    font-size: 0.75rem;
    color: white;
    background-color: #7cafcf ;
    text-transform: capitalize;
    /* cursor: not-allowed !important; */
  }

  .btn.active, .btn.upcoming {
      color: white;
      font-size: 0.75rem;
      background-color: green;
      text-transform: capitalize;
  }
  .job_desgination {
    color:#0054ad;
    font-weight: bold;
  }
  .expired, .expired p{
    color: #8f8f8f;
  }
</style>
